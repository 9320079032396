const companies = [
    {
        logo: require("@/assets/images/companies/airbnb.png"),
        name: "Airbnb Inc.",
        location: "San Francisco, California",
        revenue: "260 cr",
        employees: "3.1k"
    },
    {
        logo: require("@/assets/images/companies/apple.png"),
        name: "Apple Inc.",
        location: "Cupertino, California",
        revenue: "22,923.4 cr",
        employees: "47k"
    },
    {
        logo: require("@/assets/images/companies/google.png"),
        name: "Google LLC",
        location: "Menlo Park, California",
        revenue: "110 bn",
        employees: "72k"
    },
    {
        logo: require("@/assets/images/companies/amazon.png"),
        name: "Amazon Inc.",
        location: "Seattle, Washington",
        revenue: "17,786 cr",
        employees: "566k"
    },
    {
        logo: require("@/assets/images/companies/facebook.png"),
        name: "Facebook Inc.",
        location: "Cambridge, Massachusetts",
        revenue: "9.16 bn",
        employees: "25.1k"
    },
    {
        logo: require("@/assets/images/companies/cisco.png"),
        name: "Cisco Systems",
        location: "San Jose, California",
        revenue: "4,800.5 cr",
        employees: "73.4k"
    }
];

export { companies };
